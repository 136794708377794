/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');
// presets start
@import "/src/assets/styles/_responsive.scss";
@import "/src/assets/styles/_typography.scss";
@import "/src/assets/styles/_variables.scss";
@import "/src/assets/styles/_fonts.scss";
@import "/src/assets/styles/_ACF-Fonts.scss";

:root {
    font-size: 14px;
}

body,
body .p-component {
    font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


* {
    //font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // border: 1px solid red;
}

sup {
    color: #000000;
    font-weight: 900;
    font-size: 12px;
    position: absolute;
}

img {
    width: 100%;
}

input[type="checkbox"] {
    accent-color: #00B23E;
    color: #fff;
}

// presets ends
.p-inputtext {
    font-family: 'Roboto' !important;
    background: #FCFCFC !important;
}

.p-dropdown .p-dropdown-trigger {
    background: #FCFCFC !important;
}

.ht-5 {
    height: 5%;
}

.ht-10 {
    height: 10%;
}

.ht-20 {
    height: 20%;
}

.ht-30 {
    height: 30%;
}

.ht-40 {
    height: 40%;
}

.ht-50 {
    height: 50%;
}

.ht-60 {
    height: 60%;
}

.ht-70 {
    height: 70%;
}

.ht-75 {
    height: 75%;
}

.ht-82 {
    height: 82%;
}

.ht-80 {
    height: 80%;
}

.ht-85 {
    height: 85%;
}

.ht-90 {
    height: 90%;
}

.ht-100 {
    height: 100%;
}

.wd-5 {
    width: 5%;
}

.wd-10 {
    width: 10%;
}

.wd-15 {
    width: 15%;
}

.wd-20 {
    width: 20%;
}

.wd-25 {
    width: 25%;
}

.wd-30 {
    width: 30%;
}

.wd-40 {
    width: 40%;
}

.wd-50 {
    width: 50%;
}

.wd-60 {
    width: 60%;
}

.wd-70 {
    width: 70%;
}

.wd-75 {
    width: 75%;
}

.wd-80 {
    width: 80%;
}

.wd-85 {
    width: 85%;
}

.wd-90 {
    width: 90%;
}

.wd-100 {
    width: 100%;
}


// custom elevated button start
.elevated-btn {
    position: relative;
    z-index: 5;
    white-space: nowrap;

    &.primary {

        button:not(.p-disabled) {
            background-color: var(--button-grey);
            border-color: var(--button-grey);
            color: var(--bg-white);

            .p-button-label {
                font-weight: 600 !important;
            }

            @media screen and (max-width: 1700px) {
                .p-button-label {
                    font-size: 13px !important;
                }
            }

            @media screen and (max-width: 1600px) {
                .p-button-label {
                    font-size: 12px !important;
                }

            }


            // &:hover {
            //     background: var(--primary-button-border) !important;
            //     border-color: var(--primary-button-border) !important;
            // }

            &:focus {
                // box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--primary-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }
        }

        button.p-disabled:not(.p-button-loading) {
            opacity: unset !important;
            background-color: #798c9b;
            border-color: #798c9b;
            color: var(--bg-white);

            &:hover {
                background: var(--primary-button-border) !important;
                border-color: var(--primary-button-border) !important;
            }

            &:focus {
                // box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--primary-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }
        }

        button.p-disabled.p-button-loading {
            opacity: unset !important;
            background-color: var(--button-grey) !important;
            border-color: var(--button-grey) !important;
            justify-content: center;
            flex-direction: row-reverse;
            gap: 10px;
            align-items: center;

            &:hover {
                background: var(--primary-button-border) !important;
                border-color: var(--primary-button-border) !important;
            }

            &:focus {
                // box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--primary-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }

            .p-button-label {
                flex: none;
            }
        }

        button .pi-spinner::before {
            font-family: 'primeicons';
        }

    }

    &.secondary {
        button {
            background-color: #fff !important;
            border-color: #96444B !important;
            color: #96444B;

            .p-button-label {
                font-weight: 600 !important;
            }

            &:hover {
                color: #96444B !important;
            }

            &:focus {
                box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--secondary-button-bg), 0 1px 2px 0 black;
            }
        }

        button.p-disabled {
            opacity: 1 !important;
            background-color: #ebecec !important;
            border-color: #ebecec !important;
            color: #8a8c8c !important;
            // box-shadow: 0px 12px 15px rgba(51, 51, 85, 0.1);
        }
    }

    &.secondary-black {
        button {
            background-color: #fff !important;
            border-color: #333333 !important;
            color: #333333;

            .p-button-label {
                font-weight: 600 !important;
            }

            &:hover {
                color: #333333 !important;
            }

            &:focus {
                //box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--secondary-button-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }
        }

        button.p-disabled {
            opacity: 1 !important;
            background-color: #ebecec !important;
            border-color: #ebecec !important;
            color: #8a8c8c !important;
            // box-shadow: 0px 12px 15px rgba(51, 51, 85, 0.1);
        }
    }

    .p-button-label {
        flex: 1;
    }

    button {
        border-radius: 5px;
        max-width: 200px;
        min-width: 150px;
        height: 41px;
        justify-content: center;

        .p-button-label {
            font-family: 'Poppins' !important;
            letter-spacing: 1px !important;
        }

        @media screen and (max-width: 1700px) {
            min-width: 140px !important;
            height: 30px !important;

        }

    }
}

.elevated-btn-md {
    position: relative;
    z-index: 5;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 37%;
        transform: translate(-50%, -50%);
        width: 110px;
        height: 100%;
        background: transparent;
        border-radius: 5px;
        box-shadow: 0px 12px 15px rgba(51, 51, 85, 0.3);
        z-index: -1;
    }

    &.primary {

        button:not(.p-disabled) {
            background-color: var(--primary-bg);
            border-color: var(--primary-bg) !important;

            &:hover {
                background: var(--primary-button-border) !important;
                border-color: var(--primary-button-border) !important;
            }

            &:focus {
                // box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--primary-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }
        }

        button.p-disabled {
            opacity: unset !important;
            background-color: var(--primary-diable-bg);
            border-color: var(--primary-diable-bg) !important;

            &:hover {
                background: var(--primary-button-border) !important;
                border-color: var(--primary-button-border) !important;
            }

            &:focus {
                // box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--primary-bg), 0 1px 2px 0 black;
                box-shadow: none;
            }
        }

    }

    &.secondary {
        button {
            background-color: var(--secondary-button-bg) !important;
            border-color: var(--secondary-button-bg) !important;
            color: var(--jet-black);

            &:hover {
                color: var(--jet-black) !important;
            }

            &:focus {
                box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--secondary-button-bg), 0 1px 2px 0 black;
            }
        }

        button.p-disabled::after {
            box-shadow: 0px 12px 15px rgba(51, 51, 85, 0.1);
        }
    }

    button {
        padding: 6px 10px;
        font-weight: 300 !important;
        border-radius: 9px;
        max-width: 130px;
        min-width: 118.67px;
        height: 35px;

        span {
            font-weight: 400 !important;
            font-family: 'Roboto', sans-serif;
        }
    }
}

.p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--primary-bg) !important;
}

.p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color .2s;

    &:hover {
        background-color: var(--primary-bg);
    }
}

.p-calendar::after {
    content: '\e91e';
    font-family: 'icomoon';
    position: absolute;
    right: 5px;
    color: #BABABA;
    top: 11px;
    font-size: 14.98px;
    /* left: 26px; */
    width: 20px;
    height: 20px;
}

.grid_wrapper {
    height: 73vh !important;
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 10px;
    overflow: auto;
    // padding: 10px 0px 0px 12px; 
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

.p-radiobutton {
    border: 2px solid var(--primary-bg) !important;
    border-radius: 50% !important;
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 17px !important;
    width: 17px !important;
}

.p-radiobutton-box {
    border: 0px !important;
    width: 9px !important;
    height: 9px !important;

    &.p-highlight {
        background: var(--primary-bg) !important;
        border-color: var(--primary-bg) !important;

        .p-radiobutton-icon {
            display: none !important;
        }
    }
}


.acv-grid-scroll {
    @media screen and (max-width: 1700px) and (min-width: 630px) {
        width: 85vw;
        overflow-y: auto;
    }

    @media screen and (max-width: 629px) {
        width: 100vw;
        overflow-y: auto;
    }
}

// .page_content{
//     position: relative;
//     // width: 81vw;
// }

.page_content_wrapper {
    overflow: auto;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px 7px 20px 0px;
}

.page_footer_wrapper {
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px 0px 0px 0px;
}

.form-group {
    .p-dropdown {
        display: flex;
        margin-top: 0.5rem;
    }
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #88eaac !important;
    border-color: var(--secondary-bg) !important;
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled).p-focus {
    // box-shadow: 0 0 0 0.2rem #88eaac !important;
    // border-color: var(--secondary-bg) !important;

    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.05) !important;
    border-color: var(--primary-bg) !important;
}

.p-inputtext:enabled:hover,
.p-dropdown:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled):hover {
    // border-color: var(--secondary-bg) !important;

    border-color: var(--primary-bg) !important;
}

.detail-Mainheading {
    color: var(--jet-black);
    font-weight: 500;
    font-size: 16px;
}

.detail-heading {
    color: var(--grid-header-text-color);
    font-weight: 500;
    font-size: 14px;
}

.detail-data {
    color: var(--jet-black);
    font-weight: 500;
    font-size: 13px;
}

.multi-selectDD {
    // .p-component .p-multiselect-chip{
    //     .p-overlay .p-overlay-content 
    // }

    .p-multiselect-panel {
        border: 1px solid #D8D8D8 !important;
    }

    .p-overlay {
        top: -160px !important;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: var(--primary-bg);
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background-color: var(--primary-bg);
        border-color: var(--primary-bg);
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: var(--primary-bg);
        border-color: var(--primary-bg);
    }

    .p-checkbox .p-checkbox-box {
        width: 18px;
        height: 18px;
        border-radius: 3px;
    }


}

// .p-datepicker{
//     top: 52px !important;
// }

.p-datepicker table td {
    padding: 0rem !important;

    span {
        width: 2rem !important;
        height: 2rem !important;
    }
}

.p-datepicker table td.p-datepicker-today>span {
    background: var(--primary-bg) !important;
    color: var(--bg-white) !important;
}

.app-modal {
    .p-dialog-header {
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
        padding: 1rem 1.5rem 1rem 1.5rem !important;
    }

    .p-dialog-title {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: var(--jet-black) !important;
    }

    .p-dialog-content {
        border-bottom-right-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
    }
}

//filter error
.filter .p-calendar .p-inputtext {
    border: 1px solid red;
}

// custom avatar css
.custom-avatar {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* border: 7px solid transparent; */
    background-color: rgba(255, 255, 255, 0.2);
    align-items: center;
    text-align: center;
    justify-content: center;

    label {
        background: #e0c7c9;
        border-radius: 50%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        color: #96444B;
        // centering the letters
        width: 95px;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.products_overlay {
    min-height: 314px;
    max-height: 314px;
    overflow: auto;

    &::before,
    &::after {
        display: none;
    }
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-bg);
    border-radius: 10px;
    min-height: 100px;
}

input.p-inputtext[disabled] {
    background: #D9DADE !important;
}

.p-dropdown.p-disabled,
.p-dropdown.p-disabled>* {
    background: #e4e7e8 !important;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #e5d5d6 !important;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: #96444B !important;
    font-weight: 500 !important;
}

.p-tieredmenu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 0.15rem #96444B !important;
}

.multiselect-overlayPanelClass {
    width: 355px !important;
    margin-top: 8px !important;
}

.phonecode-overlayPanelClass {
    margin-top: 8px !important;
    margin-left: -11px;
}

.fontstyle-phonecode{
    color: var(--jet-black) !important;
    font-family: 400;
}
.p-dropdown-panel .p-dropdown-items {
    padding: 0px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    background-color: #fafafa !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    color: var(--primary-bg) !important;
    background-color: var(--active-menu-bg) !important;
}

.calander-overlay-panel{
    .p-overlaypanel-content{
        padding: 10px !important;
    }

    .btn-status {
        border-radius: 5px !important;
        height: 22px !important;
        // width: 36% !important;
        font-size: 11px !important;
        color: var(--dim-black) !important;
        justify-content: center !important;
        cursor: unset;

        .p-button-label {
            font-weight: 400 !important;
            color: var(--jet-black) !important;
        }

        &:hover{
            background: none !important;
        }

        &:focus{
            background: none !important;
            box-shadow: none !important;
        }

        &[status="New"],
        &[status="On Hold"],
        &[status="Active"],
        &[status="Not Started"],
        &[status="Not Assigned"] {
            border-color: var(--sky-blue) !important;
            color: var(--sky-blue) !important;
            background-color: rgba(62, 169, 245, 0.1) !important;
        }

        &[status="Failed"],
        &[status="Void"],
        &[status="Back-Order"] {
            border-color: var(--dim-orange) !important;
        }

        &[status="In-Active"],&[status="Inactive"],&[status="Rejected"],&[status="Due Today"],
        &[status="Open"],
        &[status="Draft"] {
            color: #eb5858 !important;
            border: 2px solid #f1a1a1 !important;
            background-color: #f8eaea !important;
            font-family: 'Poppins';
            &:focus{
                background-color: rgba(235, 88, 88, 0.1) !important;
            }
        }
    }

    .ic-location::before{
        color: #96444B;
    }

    .elevated-btn button{
        min-width: 100px !important;
        height: 25px !important;
        font-size: 12px;
    }
}

.calander-info{
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #E4E3E3;
    width: 400px;
    
    .btn-status {
        border-radius: 5px !important;
        height: 22px !important;
        // width: 36% !important;
        font-size: 11px !important;
        color: var(--dim-black) !important;
        justify-content: center !important;
        cursor: unset;

        .p-button-label {
            font-weight: 400 !important;
            color: var(--jet-black) !important;
        }

        &:hover{
            background: none !important;
        }

        &:focus{
            background: none !important;
            box-shadow: none !important;
        }

        &[status="New"],
        &[status="On Hold"],
        &[status="Active"],
        &[status="Not Started"],
        &[status="Not Assigned"] {
            border-color: var(--sky-blue) !important;
            color: var(--sky-blue) !important;
            background-color: rgba(62, 169, 245, 0.1) !important;
        }

        &[status="Failed"],
        &[status="Void"],
        &[status="Back-Order"] {
            border-color: var(--dim-orange) !important;
        }

        &[status="In-Active"],&[status="Inactive"],&[status="Rejected"],&[status="Due Today"],
        &[status="Open"],
        &[status="Draft"] {
            color: #eb5858 !important;
            border: 2px solid #f1a1a1 !important;
            background-color: #f8eaea !important;
            font-family: 'Poppins';
            &:focus{
                background-color: rgba(235, 88, 88, 0.1) !important;
            }
        }

        &[status="Due Tomorrow"], &[status="In Progress"] {
            border-color: #f1d2a1 !important;
            background-color: #f8f3ea !important;
            color: #ebb258 !important;
        }

        &[status="Completed"] {
            border-color: #8ed575 !important;
            background-color: #e6f3e2 !important;
            color: #36b808 !important;
        }

        &[status="Cancelled"] {
            border-color: #8c8c8c !important;
            background-color: #e6e6e6 !important;
            color: #333333 !important;
        }
    }

    .ic-location::before{
        color: #96444B;
    }

    .elevated-btn button{
        min-width: 100px !important;
        height: 25px !important;
        font-size: 12px;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .hidden-xs {
        display: none !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .hidden-md {
        display: none !important;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .hidden-md {
        display: none !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .hidden-lg {
        display: none !important;
    }

    .ht-80 {
        height: 76vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) -- This working on my PC */
@media only screen and (min-width: 1200px) {
    .hidden-xl {
        display: none !important;
    }

    .ht-80 {
        height: 76vh;
    }

    // .ht-75{
    //     height: 80%;
    // }
}

@media only screen and (max-device-width: 1600px) {
    .ht-75 {
        height: 77%;
    }


}